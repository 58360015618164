<template>
    <div class="zt-page-content" ref="load">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck1" label-width="120px">
            <div class="zt-block">
                <div class="zt-block-head">销售信息</div>
                <div class="zt-block-content">
                    <el-form-item label="套系名称" prop="name">
                        <el-input v-model="ruleForm.name" maxlength="30" placeholder="填写套系名称"></el-input>
                        <span class="font14-grey1" style="padding-left:10px;line-height:20px;position:relative;top:10px;"
                            >{{(ruleForm.name || '').length}}/30</span>
                    </el-form-item>
                    <el-form-item label="套系风格" prop="style_no">
                        <el-select v-model="ruleForm.style_no" placeholder="请选择套系风格">
                            <el-option v-for="item in styles" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="渲染图" prop="photos">
                        <div>
                            <UploadImgs v-if="showImgs" :limit="5" v-model="ruleForm.photos" prefix='product/series'></UploadImgs>
                        </div>
                    </el-form-item>
                </div>
            </div>
            <div class="zt-block">
                <div class="zt-block-head">添加作品</div>
                <div class="zt-block-content table">
                    <el-row class="table-header flex">
                        <el-col :span="5">分类</el-col>
                        <el-col :span="8">作品名称</el-col>
                        <el-col :span="6">类目</el-col>
                        <el-col :span="5">操作</el-col>
                    </el-row>
                    <div class="table-body" v-for="(item, idx) in set" :key="idx">
                        <div class="tb-head flex">
                            <div class="flex-1">{{item.name}}</div>
                            <div v-if="idx>0">
                                <el-button @click="edit_series(item)" type="text" size="small">修改分类名称</el-button>
                                <el-button @click="del_series(item,idx)" type="text" size="small">删除分类</el-button>
                            </div>
                            <!-- 分类排序 -->
                            <div v-if="idx>0" class="cats flex flex-justify-between">
                                <div>
                                    <i @click="sort_series('up', item, idx)"
                                    :class="idx==1 ? 'disabled' : ''"
                                    class="iconfont icon-jiantou up"></i>
                                </div>
                                <div>
                                    <i @click="sort_series('down', item, idx)"
                                    :class="idx==set.length-1 ? 'disabled' : ''"
                                    class="iconfont icon-jiantou down"></i>
                                </div>
                            </div>
                        </div>
                        <el-row class="tb-body" v-for="(item, idx1) in item.product_rows" :key="idx1">
                            <el-col :span="5"></el-col>
                            <el-col :span="8">{{item.name}}</el-col>
                            <el-col :span="6">
                                <span v-if="item.cat">{{item.cat.name}}</span>
                            </el-col>
                            <el-col :span="5">
                                <el-button @click="move_product(item)" type="text" size="small">移动至其他分类</el-button>
                                <el-button @click="edit_product(item)" type="text" size="small">编辑</el-button>
                                <el-button @click="del_product(item,idx,idx1)" type="text" size="small">删除</el-button>
                            </el-col>
                        </el-row>
                        <el-row class="tb-body">
                            <el-col :span="5"></el-col>
                            <el-col :span="8">
                                <el-button @click="add_product(item)" type="text" size="small">+ 添加作品</el-button>
                            </el-col>
                        </el-row>
                    </div>

                    <div>
                        <el-button @click="add_series" type="primary" size="mini">新增分类</el-button>
                    </div>
                </div>
            </div>
        </el-form>

        <el-dialog width="600px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <UpdateSeries :popTitle="popTitle" :form="setForm" :productForm="productForm" :set="set" @result="getSet" @hide="hide"></UpdateSeries>
        </el-dialog>

    </div>
</template>

<script>
    import { clone } from 'lodash';
    import { getStyles } from '@/service/item';
    import { addOrUpdateSet, multiUpdateSet, delSet, delSetProduct } from '@/service/design';
    import UploadImgs from '@/components/upload/UploadImgs.vue';
    import UpdateSeries from './UpdateSeries.vue';
    export default {
        components: {
            UploadImgs,
            UpdateSeries,
        },
        model: {
            prop: "form",
            event: "change"
        },
        props:['form','detail','set_row'],
        data() {
            return {
                popTitle:"",
                popVisible:false,
                ruleForm: {
                    id: "",
                    name: "",
                    photos: [],
                    style_no: "",
                    order_no: 0,
                },
                rules: {
                    name: [{ required: true, message: '填写商品名称', trigger: 'blur' }],
                    style_no: [{ required: true, message: '请选择风格', trigger: 'blur' }],
                    photos: [{ required: true, message: '请上传渲染图', trigger: 'blur' }],
                },
                styles: [], // 风格列表
                product_no:null,
                showImgs: false,
                set:[
                    { id: 0, name: "未分类", parent_set_no: "", order_no: 0, product_rows:[
                        // { id: 0, name: "", cat: { name: "" } }
                    ]},
                ],
                setForm: null, // 用于套系列表增删改查
                productForm: null, // 用于移动商品到某个套系下
                setting: null, // 用于套系下的商品添加编辑
            }
        },
        watch:{
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            },
            set_row:{
                handler() {
                    this.setRow();
                },
                deep: true
            },
            set:{
                handler() {
                    console.log(this.set);
                },
                deep: true
            }
        },
        async created() {
            this.styles = await getStyles();
            if (this.form) {
                this.ruleForm = this.form;
                this.set[0].id = this.form.id;
                this.showImgs = true;
            }
            if (this.detail) {
                this.set = [];
                if (this.detail.sub_set_rows && this.detail.sub_set_rows.length>0) {
                    this.set = this.detail.sub_set_rows;
                }
                if (this.set.length>0 && this.set[0].id == this.form.id) {
                    this.$set(this.set, 0, {
                        id: this.form.id,
                        parent_set_no: "",
                        name: "未分类", 
                        product_rows: this.detail.product_rows
                    }) // 触发视图更新
                } else if (this.set.length>0 && this.set[0].name != '未分类' || this.set.length==0) {
                    this.set.unshift({
                        id: this.form.id,
                        parent_set_no: "",
                        name: "未分类", 
                        product_rows: this.detail.product_rows
                    })
                }
            }
            if (this.set_row && this.set_row.id || this.set_row && this.set_row.setNo) {
                this.setRow();
            }
            // console.log("this.set",this.set,this.ruleForm);
        },
        mounted() {},
        methods: {
            async created_series() { // 添加分类、添加商品之前要先保存父级套系
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                    target: this.$refs.load
                });
                addOrUpdateSet(this.ruleForm).then(res => {
                    console.log("首次提交",res);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add_series() { // 新增分类
                this.$refs['formCheck1'].validate((valid) => {
                    if (valid) {
                        addOrUpdateSet(this.ruleForm).then(res => {
                            this.popTitle = "新增分类";
                            this.popVisible = true;
                            this.setForm = {
                                id: "", 
                                parent_set_no: this.ruleForm.id,
                                name: "", 
                                product_rows:[],
                                order_no: 0,
                            }
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    }
                });
            },
            edit_series(item) { // 修改分类
                this.popTitle = "修改分类";
                this.popVisible = true;
                this.setForm = {
                    id: item.id, 
                    parent_set_no: item.parent_set_no, 
                    name: item.name, 
                    product_rows: item.product_rows,
                    order_no: item.order_no || 0,
                }
            },
            sort_series(type, item, index) { // 分类排序
                console.log(type, item, index, this.set);
                if (type === "up") {
                    if(index!=0){
                        this.set[index] = this.set.splice(index-1, 1, this.set[index])[0];
                    }
                } else {
                    if(index!=this.set.length-1){
                        this.set[index] = this.set.splice(index+1, 1, this.set[index])[0];
                    }
                }
                let sortList = [];
                this.set.forEach((e,idx) => {
                    if (idx>0) {
                        sortList.push({ id:e.id, order_no:idx,name:e.name });
                    }
                });
                multiUpdateSet(sortList).then(rst => {
                    if (type === "up") {
                        this.$message.success("上移成功");
                    } else {
                        this.$message.success("下移成功");
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            del_series(item,index) { // 删除分类
                if (item && item.product_rows && item.product_rows.length>0) {
                    this.$message.error('请先删除该分类下面的商品!');
                    return;
                }
                this.$confirm("确定要删除？", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    type: "warning",
                }).then((err) => {
                    delSet([item.id]).then(res => {
                        console.log("res",res);
                        this.set.splice(index,1);
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            move_product(item,index,index1) { // 移动单品到某个套系下
                this.popTitle = "移动至其他分类";
                this.popVisible = true;
                this.setForm = {
                    set_no: item.setNo
                }
                this.productForm = item;
            },
            del_product(item,index,index1) { // 删除单品 index套系索引，index1套系下的商品索引
                let obj = {
                    set_id: item.setNo,
                    product_id: [item.id]
                };
                this.$confirm("确定要删除？", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    closeOnClickModal: false,
                    type: "warning",
                }).then((err) => {
                    delSetProduct(obj).then(res => {
                        console.log("res",res);
                        this.set[index].product_rows.splice(index1,1);
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            getSet(data) {
                console.log(data);
                this.set = data;
            },
            hide() {
                this.popVisible = false;
                this.created_series();
            },
            add_product(item) { // 添加单品
                this.$refs['formCheck1'].validate((valid) => {
                    if (valid) {
                        addOrUpdateSet(this.ruleForm).then(res => {
                            this.setting = {
                                set_no: item.id
                            }
                            this.$emit("showProduct",this.setting);
                        }).catch(err => {
                            console.log(err);
                            this.$message.error(err.message);
                        });
                    }
                });
                
            },
            edit_product(item) { // 编辑单品商品
                this.setting = {
                    id: item.id,
                    set_no: item.setNo
                }
                this.$emit("showProduct",this.setting);
            },
            setRow() {
                console.log("this.set_row",this.set_row,this.set);
                let index = this.set.findIndex(e => e.id == this.set_row.setNo);
                if (this.set[index].product_rows && this.set[index].product_rows.length>0) {
                    let index1 = this.set[index].product_rows.findIndex(e => e.id == this.set_row.id);
                    console.log("index1",index1);
                    if (index1>=0) {
                        this.$set(this.set[index].product_rows, index1, this.set_row) // 触发视图更新
                    } else {
                        this.set[index].product_rows.push(this.set_row);
                    }
                } else {
                    this.set[index].product_rows.push(this.set_row);
                }
                console.log("this.set_row",this.set_row,this.set);
            },
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            // 保存
            submitForm1() {
                let flag = false;
                this.set.forEach((e)=>{
                    if (e.product_rows && e.product_rows.length>0) {
                        flag = true;
                    }
                })
                let _item = clone(this.ruleForm);
                if (flag) {
                    this.$refs['formCheck1'].validate((valid) => {
                        if (valid) {
                            this.submit(_item);
                        }
                    });
                } else {
                    this.$message.error("请至少添加一个作品！");
                }
            },
            submit(_item) {
                const _loading = this.$loading({
                    lock: true,
                    text: '拼命加载中......',
                });
                addOrUpdateSet(_item).then(rst => {
                    this.$emit("step",_item,2);
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    this.$message.error(err.message);
                });
            },
        }
    }
</script>
<style>

</style>
<style scoped lang="less">
.el-input, .el-cascader, .el-select {
    width: 240px;
}
.table {
    padding-left: 100px;
    .table-header {
        padding: 0 30px;
        font-size: 16px;
        color: #3B3B3B;
        font-weight: bold;
        .el-col {
            height: 40px;
            // text-align: center;
            line-height: 40px;
        }
    }
    .table-body {
        background: #F5F5F5;
        margin-bottom: 16px;
        .cats {
            width: 40px;
            margin-left: 20px;
            >div {
                width: 16px;
                overflow: hidden;
            }
            i {
                color: #016fff;
                display: block;
                cursor: pointer;
                &.disabled {
                    pointer-events: none;
                    cursor: default;
                    color: #cccccc;
                }
            }
            .down {
                transform: rotate(90deg);
            }
            .up {
                transform: rotate(-90deg);
            }
        }
        .tb-head {
            padding: 0 30px;
            height: 50px;
            line-height: 50px;
            background: #DBE1F6;
        }
        .tb-body {
            padding: 0 30px;
            .el-col {
                height: 40px;
                line-height: 40px;
                //   text-align: center;
            }
        }
    }
}
</style>